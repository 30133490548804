import React, { useState, useEffect } from 'react';
import SimpleTable from './SimpleTable';

const Modal = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null;

  return (
    <div style={{
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }}>
      <div style={{
        backgroundColor: 'white',
        padding: '20px',
        borderRadius: '5px',
        maxWidth: '80%',
        maxHeight: '80%',
        overflow: 'auto',
      }}>
        <button onClick={onClose} style={{ float: 'right' }}>Close</button>
        {children}
      </div>
    </div>
  );
};

const App = () => {
  const [data, setData] = useState([]);
  const [title, setTitle] = useState("Morpho Vaults Optimal Allocation");
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedTransaction, setSelectedTransaction] = useState('');

  useEffect(() => {
    const fetchData = () => {
      fetch('/morpho_vaults_results.json')
        .then(response => response.json())
        .then(data => setData(data));
    };

    fetchData(); // Fetch immediately on mount
    const intervalId = setInterval(fetchData, 120000); // Fetch every 2 minutes
    return () => clearInterval(intervalId); // Clean up on unmount
  }, []);

  const openModal = (transaction) => {
    setSelectedTransaction(transaction);
    setModalIsOpen(true);
  };

  const hasTransaction = (transaction) => {
    return transaction && Object.keys(transaction).length > 0;
  };

  const formatTransaction = (transaction) => {
    try {
      // Parse the JSON string
      const parsedTransaction = JSON.parse(transaction);
      // Convert back to a formatted JSON string
      return JSON.stringify(parsedTransaction, null, 2);
    } catch (error) {
      console.error("Error parsing transaction:", error);
      return "Error: Unable to parse transaction data";
    }
  };

  return (
    <div style={{
      margin: 0,
      padding: 0,
      width: '100%',
      boxSizing: 'border-box',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    }}>
      <div style={{
        width: '100%',
        maxWidth: '100%',
        padding: '20px',
        boxSizing: 'border-box',
      }}>
        <h1 style={{ fontSize: '24px', marginBottom: '20px', textAlign: 'center' }}>Morpho Vaults Asset Allocation</h1>
        {data.map((vault, index) => (
          <div key={index} style={{ marginBottom: '40px', width: '100%' }}>
            <h2 style={{ fontSize: '20px', marginBottom: '10px' }}>{vault.name} ({vault.chain})</h2>
            <p>
              Old Avg APY ({vault.avg_days}d): {(vault.old_apy * 100).toFixed(3)}% | 
              New Avg APY ({vault.avg_days}d): {(vault.new_apy * 100).toFixed(3)}%
              {hasTransaction(vault.transaction) ? (
                <button 
                  onClick={() => openModal(vault.transaction)}
                  style={{
                    marginLeft: '10px',
                    padding: '5px 10px',
                    backgroundColor: '#4CAF50',
                    color: 'white',
                    border: 'none',
                    borderRadius: '3px',
                    cursor: 'pointer',
                  }}
                >
                  View Transaction
                </button>
              ) : (
                <span style={{
                  marginLeft: '10px',
                  color: 'red',
                }}>
                  Transaction Unavailable
                </span>
              )}
            </p>
            <p>Absolute Change in USD: ${vault.abs_change_usd.toLocaleString(undefined, { maximumFractionDigits: 2 })}</p>
            <SimpleTable data={vault.old_markets} />
            <SimpleTable data={vault.new_markets} />
          </div>
        ))}
      </div>
      <Modal isOpen={modalIsOpen} onClose={() => setModalIsOpen(false)}>
        <h2>Transaction Details</h2>
        <pre style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}>
          {formatTransaction(selectedTransaction)}
        </pre>
      </Modal>
    </div>
  );
};

export default App;