import React from 'react';

const formatNumber = (value) => {
  if (typeof value === 'number') {
    if (Math.abs(value) >= 1000) {
      return value.toLocaleString(undefined, { maximumFractionDigits: 2 });
    } else {
      return value.toLocaleString(undefined, { maximumFractionDigits: 5 });
    }
  }
  return value;
};

const SimpleTable = ({ data }) => {
  if (!data || data.length === 0) {
    return <p>No data available</p>;
  }

  const headers = Object.keys(data[0]);

  return (
    <table style={{ borderCollapse: 'collapse', width: 'auto', marginBottom: '30px' }}>
      <thead>
        <tr>
          {headers.map((header) => (
            <th key={header} style={{ border: '1px solid black', padding: '4px 8px 4px 8px', textAlign: 'left', fontSize: '13px' }}>
              {header}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data.map((row, rowIndex) => (
          <tr key={rowIndex}>
            {headers.map((header) => (
              <td key={header} style={{ border: '1px solid black', padding: '4px 8px 4px 8px', fontSize: '13px' }}>
                {formatNumber(row[header])}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default SimpleTable;